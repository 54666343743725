<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
        </b-row>

        <b-row>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="单位名称"
                label-for="type"
                label-size="sm"
            >
              <b-form-input
                  class="d-inline-block mr-1"
                  size="sm"
                  v-model="condition.company_name"
                  disabled
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="店铺团队"
                label-for="type"
                label-size="sm"
            >
              <b-form-input
                  class="d-inline-block mr-1"
                  size="sm"
                  v-model="condition.store_name"
                  disabled
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库名称"
                label-for="type"
                label-size="sm"
            >
              <b-form-input
                  class="d-inline-block mr-1"
                  size="sm"
                  v-model="condition.warehouse_name"
                  disabled
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="销售类型"
                label-for="type"
                label-size="sm"
            >
              <b-form-input
                  class="d-inline-block mr-1"
                  size="sm"
                  v-model="condition.type"
                  disabled
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="期初余额"
                label-for="type"
                label-size="sm"
            >
              <b-form-input
                  class="d-inline-block mr-1"
                  size="sm"
                  v-model="condition.should_receive"
                  disabled
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="收款状态"
                label-for="type"
                label-size="sm"
            >
              <v-select
                  v-model="condition.condition.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="label"
                  :options="getCodeOptions('statement_receive_status')"
                  class="select-size-sm"
              />
            </b-form-group>
          </b-col>

          <!--          <b-col md="4" cols="12" class="mb-2">-->
          <!--            <xy-input-button label="日期"-->
          <!--                             type="time"-->
          <!--                             v-on:change="fromChildren($event,'time')"-->
          <!--                             :params="['time']"-->
          <!--                             :value="time"-->
          <!--                             :maxTime="new Date().getTime()/1000"-->
          <!--                             :buttonDisable="true"-->
          <!--            >-->
          <!--            </xy-input-button>-->
          <!--          </b-col>-->

                    <b-col
                        cols="12"
                        md="12"
                        class="demo-inline-spacing d-flex align-items-center justify-content-end"
                    >
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="Export"
                      >
                        <span class="align-right">导出</span>
                      </b-button>
                    </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="4" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.salesPrice}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.returnBoundPrice}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.costPrice}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.receivePrice}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.receiveCostPrice}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.finalReceive}}</strong></span>
            </b-td>
          </b-tr>
        </template>

        <template #cell(sales_price)="data">
            {{data.item.ext.salesPrice}}
        </template>

        <template #cell(return_bound_price)="data">
          <div class="text-danger">
            {{data.item.ext.returnBoundPrice}}
          </div>
        </template>

        <template #cell(cost_price)="data">
          {{data.item.ext.costPrice}}
        </template>

        <template #cell(receive_price)="data">
          {{data.item.ext.receivePrice}}
        </template>

        <template #cell(receive_cost_price)="data">
          {{data.item.ext.receiveCostPrice}}
        </template>

        <template #cell(final_receive)="data">
          {{data.item.ext.finalReceive}}
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
<!--          <b-col-->
<!--              cols="12"-->
<!--              sm="6"-->
<!--              class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
<!--          >-->

<!--            <b-pagination-->
<!--                v-model="start"-->
<!--                :total-rows="listTotals"-->
<!--                :per-page="limit"-->
<!--                first-number-->
<!--                last-number-->
<!--                class="mb-0 mt-1 mt-sm-0"-->
<!--                prev-class="prev-item"-->
<!--                next-class="next-item"-->
<!--            >-->
<!--              <template #prev-text>-->
<!--                <feather-icon-->
<!--                    icon="ChevronLeftIcon"-->
<!--                    size="18"-->
<!--                />-->
<!--              </template>-->
<!--              <template #next-text>-->
<!--                <feather-icon-->
<!--                    icon="ChevronRightIcon"-->
<!--                    size="18"-->
<!--                />-->
<!--              </template>-->
<!--            </b-pagination>-->

<!--          </b-col>-->

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isNumber,
  isEmpty
} from '@core/utils/filter'
import statementUseList from './settlementReceiveSumUseItemList'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import StatementCostList from "@/views/apps/statementcost/StatementCostList";
import {useToast} from "vue-toastification/composition";
import {useRouter} from "@core/utils/utils";
import axios from "@/libs/axios";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import settlementReceiveSumStore from "../settlementReceiveSumStore";

export default {
  components: {
    StatementCostList,
    AttachmentUpload,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      table: [],
    }
  },
  props: {
    condition: {}
  },
  setup(props) {
    const toast = useToast()
    const router = useRouter()
    // Register module
    if (!store.hasModule('settlementreceivesum')) store.registerModule('settlementreceivesum', settlementReceiveSumStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('settlementreceivesum')) store.unregisterModule('settlementreceivesum')
    })


    const toRefetch = function (val) {
      refetchData()
    }

    const Export = function () {
      let status = '1,2,3'
      if (!isEmpty(this.condition.condition.status)){
        const array = []
        for (let i = 0; i < this.condition.condition.status.length; i++) {
          array.push(this.condition.condition.status[i].value)
        }
        status = array.join(",")
      }
      axios({
            url: '/api/exportexcelfile/exportSettlementReceiveSumItem',
            method:'get',
            params: {
              time:this.condition.condition.time,
              companyId:this.condition.company_id,
              warehouseId:this.condition.warehouse_id,
              storeId:this.condition.store_id,
              companyName:this.condition.company_name,
              warehouseName:this.condition.warehouse_name,
              storeName:this.condition.store_name,
              type:this.condition.type,
              status:status,
              shouldReceive:this.condition.should_receive
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "应收明细-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      time,
      ext,

      // UI
    } = statementUseList(props.condition)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      ext,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      isEmpty,
      time,
      toRefetch,
      addNumber,
      isNumber,
      Export,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
