import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCodeOptions, isEmpty} from "@core/utils/filter";

export default function statementUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'type', label: '单据类型'},
    { key: 'time', label: '日期'},
    { key: 'no', label: '编号'},
    { key: 'relation_no', label: '关联编号'},
    { key: 'sales_price', label: '销售金额', sortable: true },
    { key: 'return_bound_price', label: '退货金额', sortable: true },
    { key: 'cost_price', label: '费用金额', sortable: true },
    { key: 'receive_price', label: '实收金额', sortable: true },
    { key: 'receive_cost_price', label: '收款费用', sortable: true },
    { key: 'final_receive', label: '期末余额', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('statement_id')
  const isSortDirDesc = ref(true)
  const condition = ref({})
  const ext = ref({})
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    let status = '1,2,3'
    if (!isEmpty(config.condition.status)){
      const array = []
      for (let i = 0; i < config.condition.status.length; i++) {
        array.push(config.condition.status[i].value)
      }
      status = array.join(",")
    }
    store
      .dispatch('settlementreceivesum/searchItem', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: '',
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        time:config.condition.time,
        companyId:config.company_id,
        warehouseId:config.warehouse_id,
        storeId:config.store_id,
        type:config.type,
        status:status,
        shouldReceive:config.should_receive
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        ext.value = data.ext
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    condition,
    ext,
    // Extra Filters
  }
}
