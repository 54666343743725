import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCodeOptions, isEmpty} from "@core/utils/filter";
import axios from "@/libs/axios";

export default function statementUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'type', label: '类型'},
    { key: 'company_id', label: '单位编码'},
    { key: 'company_name', label: '单位名称'},
    { key: 'company_kind', label: '公司类别'},
    { key: 'store_name', label: '所属团队', sortable: true },
    // { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'return_money_company_name', label: '回款主体', sortable: true },
    { key: 'should_receive', label: '期初应收', sortable: true },
    { key: 'sales_order', label: '本期销售出库', sortable: true },
    { key: 'sales_return_bound', label: '本期销售退货', sortable: true },
    { key: 'cost', label: '本期费用', sortable: true },
    { key: 'statement_receive_method', label: '本期实收', sortable: true },
    { key: 'statement_receive_cost', label: '收款费用', sortable: true },
    { key: 'final_receive', label: '期末余额', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('statement_id')
  const isSortDirDesc = ref(true)
  const ext = ref({})
  const condition = ref({
    status:getCodeOptions("statement_receive_status").slice(2,3),
    time:(new Date().getFullYear())+"-"+(new Date().getMonth() + 1)+"-01 至 "+new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()
  })
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    let status = '1,2,3'
    if (!isEmpty(condition.value.status)){
      const array = []
      for (let i = 0; i < condition.value.status.length; i++) {
        array.push(condition.value.status[i].value)
      }
      status = array.join(",")
    }
    axios
        .post('/api/exportexcelfile/searchSettlementReceiveSum1', {
          search_key: searchKey.value,
          start: start.value,
          limit: limit.value,
          order_by: 'company_id',
          order_desc: isSortDirDesc.value === true ? 'desc':'',
          time:condition.value.time,
          companyId:condition.value.companyId,
          warehouseId:condition.value.warehouseId,
          storeId:condition.value.storeId,
          type:isEmpty(condition.value.type)?null:condition.value.type.value,
          status:status,
          companyKind:isEmpty(condition.value.companyKind)?null:condition.value.companyKind.value,
          returnMoneyCompanyId:condition.value.returnMoneyCompanyId,
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          ext.value = data.ext
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    // store
    //   .dispatch('settlementreceivesum/search', {
    //     search_key: searchKey.value,
    //     start: start.value,
    //     limit: limit.value,
    //     order_by: '',
    //     order_desc: isSortDirDesc.value === true ? 'desc':'',
    //     time:time.value,
    //     companyId:condition.value.companyId,
    //     warehouseId:condition.value.warehouseId,
    //     storeId:condition.value.storeId,
    //     type:isEmpty(condition.value.type)?null:condition.value.type.value,
    //     status:status,
    //   })
    //   .then(response => {
    //     const data = response.data.data
    //     const list = data.ext.list
    //     listTotals.value = data.ext.totals
    //     calPrice.value = data.ext.price
    //
    //     callback(list)
    //   })
    //   .catch((e) => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: '列表获取错误',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    condition,
    ext,

    // Extra Filters
  }
}
