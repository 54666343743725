<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售类型"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      id="type"
                      :options="typeArray"
                      v-model="condition.type"
                      name="type"
                      class="select-size-sm"
                      placeholder="请选择销售类型"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款状态"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      v-model="condition.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="getCodeOptions('statement_receive_status')"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公司类别"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('company_kind_ZDKH')"
                      v-model="condition.companyKind"
                      name="type"
                      class="select-size-sm"
                      placeholder="请选择公司类别"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['time'])"
                                 :params="['time']"
                                 :value="condition.time"
                                 :maxTime="new Date().getTime()/1000"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-2"
              >
                <xy-input-button
                    label="单位名称"
                    type="input"
                    v-on:change="fromChildren($event,['companyName','companyId'])"
                    :params="['companyName','companyId']"
                    :value="condition.companyName"
                    modalName="终端客户和渠道"
                    placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>

<!--              <b-col-->
<!--                  cols="12"-->
<!--                  md="4"-->
<!--              >-->
<!--                <xy-input-button-->
<!--                    label="仓库名称"-->
<!--                    type="input"-->
<!--                    v-on:change="fromChildren($event,['warehouseName','warehouseId'])"-->
<!--                    :params="['warehouseName','warehouseId']"-->
<!--                    :value="condition.warehouseName"-->
<!--                    modalName="仓库"-->
<!--                    placeholder="点击选择仓库"-->
<!--                >-->
<!--                </xy-input-button>-->
<!--              </b-col>-->

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="店铺团队"
                    type="input"
                    v-on:change="fromChildren($event,['storeName','storeId'])"
                    :params="['storeName','storeId']"
                    :value="condition.storeName"
                    modalName="店铺"
                    placeholder="点击选择店铺"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="回款主体"
                    type="input"
                    v-on:change="fromChildren($event,['returnMoneyCompanyName','returnMoneyCompanyId'])"
                    :params="['returnMoneyCompanyName','returnMoneyCompanyId']"
                    :value="condition.returnMoneyCompanyName"
                    modalName="销售主体"
                    placeholder="点击选择回款主体"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="Export"
                >
                  <span class="align-right">导出</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="6" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.shouldReceive}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.salesOrder}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.salesReturnBound}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong></strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.statementReceiveMethod}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.statementReceiveCost}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ext.finalReceive}}</strong></span>
            </b-td>
          </b-tr>
        </template>


        <template #cell(company_name)="data">
          <div @click="showItem(data)" class="text-primary">
            {{data.item.company_name}}
          </div>
        </template>

        <template #cell(should_receive)="data">
            {{data.item.ext.shouldReceive}}
        </template>

        <template #cell(sales_order)="data">
          {{data.item.ext.salesOrder}}
        </template>

        <template #cell(company_kind)="data">
          {{getCodeLabel(data.item.type === '线上' ? "company_kind_QDS" : "company_kind_ZDKH",data.item.company_kind)}}
        </template>

        <template #cell(sales_return_bound)="data">
          <div class="text-danger">
            {{data.item.ext.salesReturnBound}}
          </div>
        </template>

        <template #cell(cost)="data">
          {{''}}
        </template>

        <template #cell(statement_receive_method)="data">
          {{data.item.ext.statementReceiveMethod}}
        </template>

        <template #cell(statement_receive_cost)="data">
          {{data.item.ext.statementReceiveCost}}
        </template>

        <template #cell(final_receive)="data">
          {{data.item.ext.finalReceive}}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        ref="modal"
        ok-only
        cancel-title="取消"
        centered
        size="xl"
        title="应收明细"
    >
      <settlement-receive-sum-item-list
          :condition="itemCondition"
      />
    </b-modal>


  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isNumber,
  isEmpty
} from '@core/utils/filter'
import statementUseList from './settlementReceiveSumUseList'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import StatementCostList from "@/views/apps/statementcost/StatementCostList";
import {useToast} from "vue-toastification/composition";
import {useRouter} from "@core/utils/utils";
import axios from "@/libs/axios";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import settlementReceiveSumStore from "./settlementReceiveSumStore";
import SettlementReceiveSumItemList from "@/views/apps/settlementreceivesum/item/settlementReceiveSumItemList";

export default {
  components: {
    SettlementReceiveSumItemList,
    StatementCostList,
    AttachmentUpload,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    searchByCondition() {
      this.reload();
    },
    resetCondition() {
      this.condition = {}
      this.reload()
    },
  },
  data() {
    return {
      typeArray: [
        {value: 1, label: '线上'},
        {value: 2, label: '线下'},
      ],
      itemCondition:{},
      advanced_search_modal: false,
      statementId:0,
      selectedItem: {},
      remarkInfo: null,

      checked: [],
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      advanced_search_modal_excel: false,
      show: true,
      excelSqlId: '',
    }
  },
  setup() {
    const toast = useToast()
    const router = useRouter()
    // Register module
    if (!store.hasModule('settlementreceivesum')) store.registerModule('settlementreceivesum', settlementReceiveSumStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('settlementreceivesum')) store.unregisterModule('settlementreceivesum')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      console.log(params)
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const toRefetch = function (val) {
      refetchData()
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked.length = 0
      this.indeterminate = false
      this.allchecked = false
      refetchData()
    }

    const Export = function () {
      let status = '1,2,3'
      if (!isEmpty(condition.value.status)){
        const array = []
        for (let i = 0; i < condition.value.status.length; i++) {
          array.push(condition.value.status[i].value)
        }
        status = array.join(",")
      }
      axios({
            url: '/api/exportexcelfile/exportSettlementReceiveSum1',
            method:'get',
            params: {
              time:condition.value.time,
              companyId:condition.value.companyId,
              warehouseId:condition.value.warehouseId,
              storeId:condition.value.storeId,
              type:isEmpty(condition.value.type)?null:condition.value.type.value,
              status:status,
              companyKind:isEmpty(condition.value.companyKind)?null:condition.value.companyKind.value,
              returnMoneyCompanyId:condition.value.returnMoneyCompanyId,
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "应收汇总-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const showItem = function (params) {
      this.itemCondition = params.item
      this.itemCondition.condition = this.condition
      this.$refs["modal"].show()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      ext,

      // UI
    } = statementUseList(
            {
              id:router.route.value.query.id,
              type:router.route.value.query.type
            }
    )

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      condition,
      ext,

      advanced_search,
      fromChildren,
      toRefetch,
      reload,
      addNumber,
      isNumber,
      Export,
      showItem,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
